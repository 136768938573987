.image {
  width: 50%;
  position: relative;
  left: 50%;
  top: 0;
  transform: translate(-50%);
}
.video {
  position: relative;
  /* width: 900px; */
  width: 100%;
}
.title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #2d3748;
  margin: 0;
  margin-bottom: 25px;
}
.subtitle-2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #2d3748;
  font-size: 28px;
  margin: 0;
  margin-bottom: 20px;
}
.subtitle-3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #2d3748;
  font-size: 22px;
  margin: 0;
  margin-bottom: 10px;
}
.subtitle-4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #2d3748;
  font-size: 18px;
  margin: 0;
  margin-bottom: 10px;
}
.subtitle-5 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #2d3748;
  font-size: 18px;
  margin: 0;
  margin-bottom: 10px;
}
.content {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #4d4d4d;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.08px;
  margin: 0;
  margin-bottom: 20px;
  text-decoration: none !important;
}
.content.center {
  text-align: center;
}
u {
  text-decoration: none !important;
}
ul {
  text-decoration: none !important;
}
o {
  text-decoration: none !important;
}
ol {
  text-decoration: none !important;
}
em {
  margin: 0;
}
sup {
  font-size: 8px;
}
sub {
  font-size: 8px;
}
strong {
  font-weight: 700;
}
.list {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #4d4d4d;
  line-height: 30px;
  letter-spacing: 0.08px;
  text-decoration: none !important;
  margin: 0;
}
list > strong {
  font-size: 18px !important;
}
.link {
  text-decoration: none;
}
@media screen and (min-width: 600px) {
  .title {
    font-size: 32px;
  }
  .subtitle-2 {
    font-size: 28px;
  }
  .subtitle-3 {
    font-size: 22px;
  }
  .subtitle-4 {
    font-size: 18px;
  }
  .subtitle-5 {
    font-size: 18px;
  }
  .content {
    font-size: 18px;
    line-height: 30px;
  }
  sup > strong {
    font-size: 11px !important;
  }
  sub > strong {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 600px) {
  .title {
    font-size: 28px;
  }
  .subtitle-2 {
    font-size: 24px;
  }
  .subtitle-3 {
    font-size: 18px;
  }
  .subtitle-4 {
    font-size: 16px;
  }
  .subtitle-5 {
    font-size: 16px;
  }
  sup > strong {
    font-size: 8px !important;
  }
  sub > strong {
    font-size: 8px !important;
  }
}

@media screen and (min-width: 1200px) {
  .video {
    height: 700px;
  }
  .image {
    width: 50%;
    left: 50%;
    top: 0;
    transform: translate(-50%);
  }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .video {
    height: 500px;
  }
  .image {
    width: 50%;
    left: 50%;
    top: 0;
    transform: translate(-50%);
  }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .video {
    height: 400px;
  }
  .image {
    width: 80%;
    left: 50%;
    top: 0;
    transform: translate(-50%);
  }
}
@media screen and (min-width: 500px) and (max-width: 600px) {
  .video {
    height: 300px;
  }
  .image {
    width: 100%;
    left: 50%;
    top: 0;
    transform: translate(-50%);
  }
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .video {
    height: 200px;
  }
  .image {
    width: 100%;
    left: 50%;
    top: 0;
    transform: translate(-50%);
  }
}
@media screen and (min-width: 0px) and (max-width: 300px) {
  .video {
    height: 180px;
  }
  .image {
    width: 100%;
    left: 50%;
    top: 0;
    transform: translate(-50%);
  }
}
