#linkbtn {
  cursor: pointer;
}
#linkbtn:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 4px;
  background: "#007053";
  transition: 0.3s;
}
#linkbtn:hover:after {
  width: 100%;
}
