.dropdown {
  position: relative;
  cursor: pointer;
}
.dropdown-box {
  display: none;
}

.dropdown:hover .dropdown-box {
  display: block;
  position: absolute;
  z-index: 2000;
  width: 300px;
  padding: 15px;
  top: 100%;
  background-color: #2d3748;
  box-shadow: 2px 2px 5px #2d3748;
  border-radius: 5px;
  animation: appearBox 400ms ease-out;
}
.link {
  color: #ffffff;
}
.link:hover {
  color: #007053;
}
@keyframes appearBox {
  from {
    opacity: 0;
    /* top: calc(100% + 100%); */
  }
  to {
    opacity: 1;
    /* top: 100%; */
  }
}
